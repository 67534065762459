import Swiper, { Pagination, Autoplay } from 'swiper'
import Plyr from 'plyr'

Swiper.use([Pagination, Autoplay])

document.addEventListener('DOMContentLoaded', e => {

  const testimonialSliders = [...document.querySelectorAll('.testimonials')]
  if (testimonialSliders.length > 0) {
    testimonialSliders.map(t => { 
      const swiper = new Swiper(t, {
        autoHeight: true,
        breakpoints: {
          1024: {
            autoHeight: false,
            slidesPerView: 2,
          },
          1280: {
            autoHeight: false,
            slidesPerView: 2,
          },
        },
        speed: 200,
        spaceBetween: 34,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
      }) 
    })
  }

  const logogalleries = [...document.querySelectorAll('.logogallery')]
  console.log(logogalleries);
  if (logogalleries.length > 0) {
    logogalleries.map(lg => {
      const swiper = new Swiper(lg, {
        speed: 200,
        autoplay: {
          delay: 5000,
        },
        centeredSlides: true,
        slidesPerView: 'auto',
        spaceBetween: 30,
        loop: true,
      })
      console.log(swiper);
    })
  }

  const players = [...document.querySelectorAll('.wp-block-embed__wrapper')]
  if (players.length > 0) {
    players.map(p => {
      const plyr = new Plyr(p, { })
    })
  }

  const tchVideo = document.querySelector('.two-column-hero__video') 
  if (tchVideo != null) {
    tchVideo.addEventListener('click', e => {
      tchVideo.querySelector('.two-column-hero__video__poster').remove()
      tchVideo.classList.add('two-column-hero__video--loaded')
      const plyr = new Plyr(tchVideo.querySelector('.two-column-hero__video__player'), {
        autoplay: true,
        controls: ['play', 'progress', 'current-time', 'mute', 'volume', 'settings', 'fullscreen'],
      })
    })
  }

  const clsVideo = document.querySelector('.player')
  if (clsVideo != null) {
    clsVideo.addEventListener('click', e => {
      clsVideo.querySelector('.player__thumbnail-poster').remove()
      clsVideo.classList.add('.player--loaded')
      const plyr = new Plyr(clsVideo, {
        autoplay: true,
        controls: ['play', 'progress', 'current-time', 'mute', 'volume', 'settings', 'fullscreen'],
      })
    })
  } 

})

